import React from 'react'
import { Typography as MUITypography } from '@material-ui/core'
import SbEditable from 'storyblok-react'
import { monochrome } from '@helpers/palette'

// const useStyles = makeStyles({
//   root: {
//     color: value => value,
//   },
// })

const Typography = (props) => {
  const formatProps = (props) => {
    const { color, ...otherProps } = props.blok
    return { ...otherProps, color: 'inherit' }
  }

  const newProps = formatProps(props)
  return (
    <SbEditable content={props.blok}>
      <span style={{ color: monochrome(props.blok.color.color) }}>
        <MUITypography {...newProps} component="span">
          {props.blok.text}
        </MUITypography>
      </span>
    </SbEditable>
  )
}

export default Typography
